import React, { useState, useEffect, useRef, useCallback } from 'react';
import TopBanner from './TopBanner';
import Page from './Page';
import useFetchData from "./hooks/useFetchData";
import Pages from './Pages';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { deepClone } from './Utils';
import { useMsal } from "@azure/msal-react";
import { apiRequest } from "./msalConfig";
import { deepMerge } from "./Utils";

function App() {
  const { instance } = useMsal();
  const [selectedSection, setSelectedSection] = useState('Verticals');
  const [menuData, setMenuData] = useState(null);
  const [selectedMenuKey, setSelectedMenuKey] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedFunction, setSelectedFunction] = useState(null);// Undo/Redo state stacks
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [clipboard, setClipboard] = useState(null);
  const [account, setAccount] = useState(instance.getActiveAccount());
  const [token, setToken] = useState(null); // Store the JWT token
  const [isLoggingIn, setIsLoggingIn] = useState(false); // New state to track login progress
  const [environment, setEnvironment] = useState("Staging");
  const [hasChanges, setHasChanges] = useState(0);
  const hasInitializedLayers = useRef(false);

  const quillRef = useRef();
  const { verticals, newsItems, suppliers, loading, error } = useFetchData(environment);

  useEffect(() => {
    setHasChanges(0);
    setMenuData(verticals[0]);
    setSelectedMenu(verticals[0]);
    setSelectedMenuKey(verticals[0] ? verticals[0].key : null);
  }, [environment, verticals]);

  // Check for redirect response or existing session on initial load
  useEffect(() => {
    const initializeAuth = async () => {
      if (isLoggingIn) return; // Avoid multiple simultaneous interactions
      setIsLoggingIn(true);

      try {
        const response = await instance.handleRedirectPromise();
        const currentAccount = response?.account || instance.getActiveAccount();

        if (currentAccount) {
          instance.setActiveAccount(currentAccount);
          setAccount(currentAccount);
        } else {
          // No account found, start login
          await instance.loginRedirect();
        }
      } catch (error) {
        console.log("Authentication error:", error);
        await instance.loginRedirect();
      } finally {
        setIsLoggingIn(false);
      }
    };

    initializeAuth();
  }, [instance, isLoggingIn]);

  // Acquire JWT token for API access
  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent(apiRequest)
        .then((response) => {
          setToken(response.idToken); // Store the access token in state
        })
        .catch(async (error) => {
          console.log("Token acquisition error:", error);
          await instance.loginRedirect();
        });
    }
  }, [account, instance]);

  // Functions for layer management
  const handleSetLayer = useCallback((item) => {
    if (menuData && verticals) {
      verticals.forEach(vertical => {
        if (vertical.id === menuData.id) {
          deepMerge(vertical, menuData); // Merge menuData properties into this vertical object
        }
      });
    }
  
    // Find the vertical matching item.id
    const foundVertical = verticals.find(vertical => vertical.id === item.id);
    if (foundVertical) {
      setMenuData(foundVertical);
      setSelectedMenu(foundVertical);
      setSelectedFunction(null);
      setSelectedMenuKey(foundVertical.key);
    }
  }, [verticals, menuData]);
  
  useEffect(() => {
    if (!hasInitializedLayers.current && account && verticals?.length > 0) {
      handleSetLayer(verticals[0]); // Set default vertical
      hasInitializedLayers.current = true; // Mark as initialized
    }
  }, [account, verticals, handleSetLayer]);

  if (!account) {
    return <div>Redirecting to login...</div>;
  }

  const handleUndo = () => {
    if (undoStack.length === 0) return; // Nothing to undo

    // Step 1: Capture the current state of `menuData` for the redo stack
    let currentMenuData;
    let selectedKey = selectedMenuKey; // Capture the currently selected key to restore selection later

    setMenuData((prevMenuData) => {
      currentMenuData = deepClone(prevMenuData); // Capture the current state before updating

      // Step 2: Deep clone the previous menu data from the undo stack to restore
      const previousMenuData = deepClone(undoStack[undoStack.length - 1]);

      // Step 3: Update the undo stack by removing the last state
      setUndoStack((prevUndoStack) => {
        const newUndoStack = prevUndoStack.slice(0, -1);

        // Update `hasChanges` based on the new undo stack length
        setHasChanges((prevHasChanges) => {
          let newChangesCount;
          newChangesCount = newUndoStack.length > 0 ? prevHasChanges-1 : 0;
          return newChangesCount;
        });

        // Return the updated undo stack
        return newUndoStack;
      });

      // Step 4: Return the previous menu data to set it as the current state
      return previousMenuData;
    });

    // Step 5: Update the redo stack with the captured current state of `menuData`
    setRedoStack((prevRedoStack) => [...prevRedoStack, deepClone(currentMenuData)]);

    // Step 6: Update `selectedMenu` based on the previously selected key
    setMenuData((newMenuData) => {
      if (selectedKey === newMenuData.key) {
        // If the selected key matches the root menu's key
        setSelectedMenu(newMenuData);
        setSelectedMenuKey(selectedKey);
      } else {
        // Recursively find the item with `selectedKey` in the newly restored `menuData`
        const findSelectedItem = (items) => {
          if (!items) return null;

          for (let key in items) {
            if (key === selectedKey) {
              return items[key];
            }

            // Recursively search sub_menus if they exist
            if (items[key].sub_menus) {
              const found = findSelectedItem(items[key].sub_menus);
              if (found) return found;
            }
          }

          return null;
        };

        // Find the selected item if it still exists
        const newSelectedMenu =
          findSelectedItem(newMenuData.sub_menus) || newMenuData;

        if (newSelectedMenu) {
          setSelectedMenu(newSelectedMenu);
          setSelectedMenuKey(newSelectedMenu.key || selectedKey);
        } else {
          // If the selected item no longer exists, clear the selection
          setSelectedMenu(null);
          setSelectedMenuKey(null);
        }
      }

      // Return newMenuData unchanged since this function is only for updating `selectedMenu`
      return newMenuData;
    });
  };

  // Step 4: Implement Redo functionality
  const handleRedo = () => {
    if (redoStack.length === 0) return; // Nothing to redo

    // Step 1: Capture the current state of `menuData` for the undo stack
    let currentMenuData;
    let selectedKey = selectedMenuKey; // Capture the currently selected key to restore selection later

    setMenuData((prevMenuData) => {
      currentMenuData = deepClone(prevMenuData); // Capture the current state before updating

      // Step 2: Deep clone the next menu data from the redo stack to restore
      const nextMenuData = deepClone(redoStack[redoStack.length - 1]);

      // Step 3: Update the redo stack by removing the last state
      setRedoStack((prevRedoStack) => prevRedoStack.slice(0, -1));

      // Step 4: Return the next menu data to set it as the current state
      return nextMenuData;
    });

    // Step 5: Update the undo stack with the captured current state of `menuData`
    setUndoStack((prevUndoStack) => [...prevUndoStack, deepClone(currentMenuData)]);

    // Step 6: Update `selectedMenu` based on the previously selected key
    setMenuData((newMenuData) => {
      if (selectedKey === newMenuData.key) {
        // If the selected key matches the root menu's key
        setSelectedMenu(newMenuData);
        setSelectedMenuKey(selectedKey);
      } else {
        // Recursively find the item with `selectedKey` in the newly restored `menuData`
        const findSelectedItem = (items) => {
          if (!items) return null;

          for (let key in items) {
            if (key === selectedKey) {
              return items[key];
            }

            // Recursively search sub_menus if they exist
            if (items[key].sub_menus) {
              const found = findSelectedItem(items[key].sub_menus);
              if (found) return found;
            }
          }

          return null;
        };

        // Find the selected item if it still exists
        const newSelectedMenu =
          findSelectedItem(newMenuData.sub_menus) || newMenuData;

        if (newSelectedMenu) {
          setSelectedMenu(newSelectedMenu);
          setSelectedMenuKey(newSelectedMenu.key || selectedKey);
        } else {
          // If the selected item no longer exists, clear the selection
          setSelectedMenu(null);
          setSelectedMenuKey(null);
        }
      }

      // Return newMenuData unchanged since this function is only for updating `selectedMenu`
      return newMenuData;
    });

    setHasChanges((prevHasChanges) => {
      return prevHasChanges+1;
    });
  };

  // Function to copy a menu item or function
  const copyMenuItem = (key) => {
    if (!key) return;

    // Save the item to clipboard for later use
    setMenuData((prevMenuData) => {
      let itemToCopy = null;

      const findRecursively = (items, parent) => {
        if (!items) return;

        Object.keys(items).forEach((currentKey) => {
          if (currentKey === key) {
            itemToCopy = deepClone(items[currentKey]);
            if (itemToCopy.is_function) {
              setSelectedMenuKey(parent.key);
            }
          } else if (items[currentKey].sub_menus) {
            findRecursively(items[currentKey].sub_menus, items[currentKey]);
          }
        });
      };

      // Search for the menu item or function to copy
      if (prevMenuData.sub_menus) {
        findRecursively(prevMenuData.sub_menus, prevMenuData);
      }

      // Check functions as well
      if (!itemToCopy && prevMenuData.functions?.search_suppliers_functions) {
        itemToCopy = deepClone(
          prevMenuData.functions.search_suppliers_functions.find(
            (func) => func.button_id === key
          )
        );
      }

      if (!itemToCopy && prevMenuData.functions?.ask_us_a_question_functions) {
        itemToCopy = deepClone(
          prevMenuData.functions.ask_us_a_question_functions.find(
            (func) => func.button_id === key
          )
        );
      }

      // If found, set it to clipboard
      if (itemToCopy) {
        setClipboard(itemToCopy);
      }


      return prevMenuData; // No changes to menuData, only copying
    });
  };

  // Function to paste the clipboard item into the selected menu item
  const pasteMenuItem = (targetKey) => {
    if (!clipboard || !targetKey) return; // Nothing to paste

    // Save the current state to the undo stack before making changes
    setUndoStack((prevUndoStack) => [...prevUndoStack, deepClone(menuData)]);
    setRedoStack([]); // Clear the redo stack because we are making a new change

    setMenuData((prevMenuData) => {
      let updatedData = deepClone(prevMenuData); // Clone to avoid mutation issues

      // Function to create a new key with the new parent context
      const createNewKey = (clipboardKey, newParentKey) => {
        const keyBase = clipboardKey.includes('|') ? clipboardKey.split('|').pop() : clipboardKey;
        return newParentKey ? `${newParentKey}|${keyBase}_copy` : `${keyBase}_copy`;
      };

      // Recursive function to update keys for the new item and its children (for non-function items)
      const updateKeysRecursively = (item, parentKey) => {
        let newKey = createNewKey(item.key, parentKey);

        if (item.is_function && updatedData.functions?.search_suppliers_functions) {
          const functionToDuplicate = updatedData.functions.search_suppliers_functions.find(
            (func) => func.button_id === item.key
          );
          if (functionToDuplicate) {
            // Deep clone the function
            const newFunction = deepClone(functionToDuplicate);
            newFunction.button_id = newKey;
            updatedData.functions.search_suppliers_functions.push(newFunction);
          }
        }

        if (item.is_function && updatedData.functions?.ask_us_a_question_functions) {
          const functionToDuplicate = updatedData.functions.ask_us_a_question_functions.find(
            (func) => func.button_id === item.key
          );
          if (functionToDuplicate) {
            // Deep clone the function
            const newFunction = deepClone(functionToDuplicate);
            newFunction.button_id = newKey;
            updatedData.functions.ask_us_a_question_functions.push(newFunction);
          }
        }

        item.key = newKey;
        item.title = `${item.title} (Copy)`; // Indicate that it's pasted

        // Update sub_menus keys recursively if they exist and the item is not a function
        if (item.sub_menus) {
          item.sub_menus = Object.keys(item.sub_menus).reduce((acc, subMenuKey) => {
            const updatedSubMenu = deepClone(item.sub_menus[subMenuKey]);
            const updatedSubMenuWithKeys = updateKeysRecursively(updatedSubMenu, newKey);
            acc[updatedSubMenuWithKeys.key] = updatedSubMenuWithKeys;
            return acc;
          }, {});
        }

        // Update options keys if they exist
        if (item.options) {
          item.options = item.options.map((option) => {
            const updatedOption = { ...option };
            updatedOption.key = createNewKey(option.key, newKey);
            return updatedOption;
          });
        }

        return item;
      };

      // Function to search all options in a parent's sub_menu to find the original key value
      const findOptionValueInParent = (parentItems, originalKey) => {
        for (const key in parentItems) {
          if (parentItems[key].options) {
            const matchingOption = parentItems[key].options.find(
              (option) => option.key === originalKey
            );
            if (matchingOption) {
              return `${matchingOption.value} (Copy)`;
            }
          }
        }
        return `${originalKey} (Copy)`; // Default fallback if no match is found
      };

      // Handle root level paste if the target is root
      if (targetKey === updatedData.key) {
        // Update keys for the new item to be pasted at the root level
        const newItem = deepClone(clipboard);
        const updatedItem = updateKeysRecursively(newItem, targetKey);

        // Ensure new key is unique at root level
        let finalKey = updatedItem.key;
        while (updatedData.sub_menus && updatedData.sub_menus[finalKey]) {
          finalKey += '_copy';
        }
        updatedItem.key = finalKey;

        // Add the updated item to the root's sub_menus
        if (!updatedData.sub_menus) {
          updatedData.sub_menus = {};
        }
        updatedData.sub_menus[finalKey] = updatedItem;

        // Update root options if applicable
        if (!updatedData.options) {
          updatedData.options = [];
        }
        updatedData.options.push({ key: finalKey, value: updatedItem.title });

      } else {
        // For non-root level, find the appropriate target and add the item there
        const findAndPasteToTarget = (items, parentItems) => {
          if (!items) return;

          Object.keys(items).forEach((currentKey) => {
            if (currentKey === targetKey) {
              // Found the target to paste
              if (!items[currentKey].sub_menus) {
                items[currentKey].sub_menus = {};
              }

              // Update keys for the new item with correct parent context
              const newItem = deepClone(clipboard);

              if (newItem.is_function) {
                // For function items:
                // No need to modify the key in sub_menus, just paste directly
                let finalKey = `${newItem.key}_copy`;
                while (finalKey in items[currentKey].sub_menus) {
                  finalKey += '_copy';
                }

                // Add to sub_menus without modifying the key for functions
                items[currentKey].sub_menus[finalKey] = {
                  ...newItem,
                  is_function: true,
                  title: `${newItem.title} (Copy)`,
                  key: finalKey, // Ensure key is unique but not recursively updated like non-function items
                };

                // Find the corresponding option value from the new parent's sub_menu options
                const originalKey = newItem.key.split('_copy')[0];
                const parentOptionValue = findOptionValueInParent(parentItems, originalKey);

                // Update the options array with the new key
                if (!items[currentKey].options) {
                  items[currentKey].options = [];
                }
                items[currentKey].options.push({ key: finalKey, value: parentOptionValue });

                // Also update the function in search_suppliers_functions
                if (updatedData.functions?.search_suppliers_functions) {
                  const functionToDuplicate = updatedData.functions.search_suppliers_functions.find(
                    (func) => func.button_id === originalKey
                  );
                  if (functionToDuplicate) {
                    // Deep clone the function
                    const newFunction = deepClone(functionToDuplicate);
                    newFunction.button_id = finalKey;
                    updatedData.functions.search_suppliers_functions.push(newFunction);
                  }
                }

                // Also update the function in ask_us_a_question_functions
                if (updatedData.functions?.ask_us_a_question_functions) {
                  const functionToDuplicate = updatedData.functions.ask_us_a_question_functions.find(
                    (func) => func.button_id === originalKey
                  );
                  if (functionToDuplicate) {
                    // Deep clone the function
                    const newFunction = deepClone(functionToDuplicate);
                    newFunction.button_id = finalKey;
                    updatedData.functions.ask_us_a_question_functions.push(newFunction);
                  }
                }

              } else {
                // If it's a regular menu item, add it normally with updated keys
                const updatedItem = updateKeysRecursively(newItem, targetKey);

                let finalKey = updatedItem.key;
                while (finalKey in items[currentKey].sub_menus) {
                  finalKey += '_copy';
                }
                updatedItem.key = finalKey;

                items[currentKey].sub_menus[finalKey] = updatedItem;

                // Update the options array as well to include the new pasted item
                if (!items[currentKey].options) {
                  items[currentKey].options = [];
                }
                items[currentKey].options.push({ key: finalKey, value: updatedItem.title });
              }
            } else if (items[currentKey].sub_menus) {
              // Recursively search within sub_menus
              findAndPasteToTarget(items[currentKey].sub_menus, items);
            }
          });
        };

        // Find the target in sub_menus and paste the item
        if (updatedData.sub_menus) {
          findAndPasteToTarget(updatedData.sub_menus, updatedData);
        }
      }

      return updatedData;
    });

    setClipboard(null);
    setHasChanges((prevHasChanges) => {
      return prevHasChanges+1;
    });
  };

  return (
    <>
      <TopBanner title="DriverHQ Portal" instance={instance} account={account} environment={environment} setEnvironment={setEnvironment} />
      <Pages selectedSection={selectedSection} setSelectedSection={setSelectedSection} />
      <Page
        menuData={menuData}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        setSelectedMenu={setSelectedMenu}
        setMenuData={setMenuData}
        selectedMenu={selectedMenu}
        verticals={verticals}
        loading={loading}
        error={error}
        handleSetLayer={handleSetLayer}
        newsItems={newsItems}
        selectedMenuKey={selectedMenuKey}
        setSelectedMenuKey={setSelectedMenuKey}
        selectedFunction={selectedFunction}
        setSelectedFunction={setSelectedFunction}
        handleUndo={handleUndo}
        handleRedo={handleRedo}
        undoStack={undoStack}
        redoStack={redoStack}
        setUndoStack={setUndoStack}
        setRedoStack={setRedoStack}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        copyMenuItem={copyMenuItem}
        pasteMenuItem={pasteMenuItem}
        clipboard={clipboard}
        quillRef={quillRef}
        suppliers={suppliers}
        jwtToken={token}
        environment={environment}
      />
    </>
  );
}

export default App;
