import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

const SupplierLogoUploader = ({ supplier, handleInputChange, uploadFile, handleUploadedLogoSave }) => {
    const [preview, setPreview] = useState(supplier.logo_url || "");

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            try {
                const uploadedUrl = await uploadFile(selectedFile);
                handleUploadedLogoSave(uploadedUrl);
                const previewUrl = URL.createObjectURL(selectedFile);
                setPreview(previewUrl);
            } catch (error) {
                alert("File upload failed " + error);
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile) {
            try {
                const uploadedUrl = await uploadFile(droppedFile);
                handleUploadedLogoSave(uploadedUrl);
                const previewUrl = URL.createObjectURL(droppedFile);
                setPreview(previewUrl);
            } catch (error) {
                alert("File upload failed: " + error);
            }
        }
    };

    return (
        <Form.Group className="mb-3 mt-3" controlId="supplier_logo">
            <div className="d-flex">
                <div className="flex-grow-1">
                    <div
                        className="d-flex align-items-center"
                        style={{
                            border: "2px dashed #ddd",
                            padding: "10px",
                            borderRadius: "5px",
                            position: "relative"
                        }}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <input
                            type="file"
                            accept="image/png"
                            onChange={handleFileChange}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                opacity: 0,
                                cursor: "pointer",
                            }}
                        />
                        <div style={{ textAlign: "center", width: "100%" }}>
                            Drag and drop or click to upload a logo
                        </div>
                    </div>
                </div>
                <div className="ms-3">
                    {preview && (
                        <Button
                            variant="danger"
                            className="mt-1"
                            onClick={() => {
                                setPreview("");
                                handleInputChange("logo_url", ""); // Clear the logo_url
                            }}
                        >
                            Remove Logo
                        </Button>
                    )}
                </div>
            </div>
        </Form.Group>
    );
};

export default SupplierLogoUploader;