import React, { useState, useMemo, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { getNestedValue } from './Utils';
import { FiHash } from "react-icons/fi";
import EditSupplierModal from './EditSupplierModal';
import AddSupplierModal from './AddSupplierModal';
import ApiHost from './ApiHost';
import { computeSHA256Hash } from './Utils';

const SuppliersPage = ({
    data,
    columns,
    columnWidths = {},
    missingFields = [],
    configuredFields = [],
    valueMappings = {},
    toggleableColumns = [],
    availablePropertiesByVertical = {},
    environment,
    jwtToken,
    verticals
}) => {
    const [filter, setFilter] = useState('');
    const [sortKey, setSortKey] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [columnFilters, setColumnFilters] = useState({});
    const [showEditSupplierModal, setShowEditSupplierModal] = useState(false);
    const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [suppliers, setSuppliers] = useState(data);
    const [loading, setLoading] = useState(false);

    const sortData = (data, sortKey, sortOrder, getConfigurationStatus, configuredFields) => {
        return [...data].sort((a, b) => {
            let aVal, bVal;

            // If sorting by configuration status, use the special status function
            if (sortKey === 'configuration_status') {
                aVal = getConfigurationStatus(a.custom_properties, a.vertical_id);
                bVal = getConfigurationStatus(b.custom_properties, b.vertical_id);
            }
            // For fields in configuredFields, determine if they are configured or not
            else if (configuredFields.includes(sortKey)) {
                aVal = getNestedValue(a, sortKey) ? '✓' : '𐄂';
                bVal = getNestedValue(b, sortKey) ? '✓' : '𐄂';
            }
            // Default sorting for other fields
            else {
                aVal = getNestedValue(a, sortKey)?.toLowerCase();
                bVal = getNestedValue(b, sortKey)?.toLowerCase();
            }

            // Handle undefined values to always appear at the end of the sorted list
            if (aVal === undefined) return sortOrder === 'asc' ? 1 : -1;
            if (bVal === undefined) return sortOrder === 'asc' ? -1 : 1;

            // Assign sorting priorities for configuration statuses
            const statusPriority = { '✓': 1, '-': 2, '𐄂': 3 };
            const aPriority = statusPriority[aVal] !== undefined ? statusPriority[aVal] : aVal.toString();
            const bPriority = statusPriority[bVal] !== undefined ? statusPriority[bVal] : bVal.toString();

            // Compare based on priorities or direct values
            if (aPriority < bPriority) return sortOrder === 'asc' ? -1 : 1;
            if (aPriority > bPriority) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const handleShowEditSupplierModal = (supplier) => {
        setSelectedSupplier(supplier);
        setShowEditSupplierModal(true);
    };

    const handleCloseEditSupplierModal = () => {
        setSelectedSupplier(null);
        setShowEditSupplierModal(false);
    };

    const handleShowAddSupplierModal = () => {
        setShowAddSupplierModal(true);
    };

    const handleCloseAddSupplierModal = () => {
        setShowAddSupplierModal(false);
    };

    const handleCreateSupplier = async (newSupplier, resetForm) => {
        setLoading(true); // Set loading state to true

        try {
            let queryString = environment === 'Staging' ? 'staging=1' : '';
            const response = await fetch(`${ApiHost}/v1/create-provider?${queryString}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`, // Pass the token in the Authorization header
                },
                body: JSON.stringify(newSupplier),
            });

            if (response.ok) {
                console.log('Supplier created successfully');
                setSuppliers((prevData) => {
                    return [...prevData, newSupplier];
                });
                resetForm();
                handleCloseAddSupplierModal();
            } else {
                // The response has a 4xx or 5xx status code
                const errorPayload = await response.json(); // Parse the response body
                throw errorPayload.message;
            }
        } catch (error) {
            alert(error);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    const handleUpdateSupplier = async (updatedSupplier, closeModal = true) => {
        setLoading(true); // Set loading state to true
        setSuppliers((prevData) =>
            prevData.map((supplier) =>
                supplier.id === updatedSupplier.id ? updatedSupplier : supplier
            )
        );

        try {
            let queryString = environment === 'Staging' ? 'staging=1' : '';
            const response = await fetch(`${ApiHost}/v1/update-provider?${queryString}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`, // Pass the token in the Authorization header
                },
                body: JSON.stringify(updatedSupplier),
            });

            if (response.ok) {
                console.log('Supplier updated successfully');
            } else {
                // The response has a 4xx or 5xx status code
                const errorPayload = await response.json(); // Parse the response body
                console.log('Error:', errorPayload);
            }
        } catch (error) {
            console.error('Error updating supplier:', error);
        } finally {
            setLoading(false); // Reset loading state
        }

        if (closeModal) {
            handleCloseEditSupplierModal();
        }
    };

    const uploadFile = async (file) => {
        const formData = new FormData();
        // Compute the SHA-256 hash of the file
        const hash = await computeSHA256Hash(file);
        formData.append("image", file, `${hash}${file.name.match(/\.[0-9a-z]+$/i)?.[0] || ""}`);

        const response = await fetch(`${ApiHost}/v1/upload-image`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${jwtToken}`, // Pass the token in the Authorization header
            },
            body: formData,
        });

        const data = await response.json();

        if (!response.ok) {
            // The response has a 4xx or 5xx status code
            console.log('Error:', data);

            if (data && data.message) {
                throw new Error(data.message)
            }
        }
   
        return `https://cdn.driverhq.co.uk/images/${data.file_name}`;
    };

    // Function to calculate custom properties configuration status based on vertical
    const getPropertiesConfigurationStatus = useCallback((customProperties, vertical) => {
        const availableProperties = availablePropertiesByVertical[vertical] || [];
        let configuredCount = 0;

        availableProperties.forEach((property) => {
            const value = customProperties[property.key];

            if (value !== undefined) {
                if (property.value === "array" && Array.isArray(value) && value.length > 0) {
                    configuredCount += 1;
                } else if (property.value === "string" && typeof value === "string" && value.trim() !== "") {
                    configuredCount += 1;
                } else if (property.value === "int" && typeof value === "number") {
                    configuredCount += 1;
                } else if (property.value === "bool" && typeof value === "boolean") {
                    configuredCount += 1;
                }
            }
        });

        if (configuredCount === 0) {
            return "𐄂";
        } else if (configuredCount < availableProperties.length) {
            return "-";
        } else {
            return "✓";
        }
    }, [availablePropertiesByVertical]); // Add dependencies here

    // Filtered Data Calculation
    const filteredData = useMemo(() => {
        let filtered = suppliers;

        // Apply missing data filter
        if (filter === ':missing') {
            filtered = filtered.filter((row) => {
                // Check if any specified fields are missing
                const hasMissingFields = missingFields.some((key) => {
                    const value = getNestedValue(row, key);
                    return value === undefined || value === null || value === '';
                });

                // Check if configuration status is not "Configured" ✓
                const configurationStatus = getPropertiesConfigurationStatus(row.custom_properties, row.vertical_id);
                const isNotFullyConfigured = configurationStatus !== "✓";

                return hasMissingFields || isNotFullyConfigured;
            });
        }
        // Apply global search filter
        else if (filter) {
            const lowerCaseFilter = filter.toLowerCase();
            filtered = filtered.filter((row) =>
                columns.some((col) => {
                    if (configuredFields.includes(col.key)) return false;
                    const rawValue = getNestedValue(row, col.key)?.toString().toLowerCase() || '';
                    const mappedValue =
                        valueMappings[col.key] && valueMappings[col.key][getNestedValue(row, col.key)]
                            ? valueMappings[col.key][getNestedValue(row, col.key)].toLowerCase()
                            : '';
                    return rawValue.includes(lowerCaseFilter) || mappedValue.includes(lowerCaseFilter);
                })
            );
        }

        // Apply column-specific filters using mapped values
        filtered = filtered.filter((row) =>
            columns.every((col) => {
                const selectedFilters = columnFilters[col.key] || [];
                if (selectedFilters.length === 0) return true;

                const cellValue = getNestedValue(row, col.key);
                const displayValue = valueMappings[col.key] && cellValue in valueMappings[col.key]
                    ? valueMappings[col.key][cellValue]
                    : cellValue?.toString();

                return selectedFilters.includes(displayValue);
            })
        );

        return sortKey ? sortData(filtered, sortKey, sortOrder, getPropertiesConfigurationStatus, configuredFields) : filtered;
    }, [suppliers, filter, sortKey, sortOrder, columns, configuredFields, valueMappings, columnFilters, missingFields, getPropertiesConfigurationStatus]);

    // Recalculate unique dropdown values based on filtered data
    const uniqueDropdownValues = useMemo(() => {
        const values = {};
        columns.forEach((col) => {
            const uniqueValues = new Set();
            filteredData.forEach((row) => {
                const rawValue = getNestedValue(row, col.key);
                const displayValue = valueMappings[col.key] && rawValue in valueMappings[col.key]
                    ? valueMappings[col.key][rawValue]
                    : rawValue;
                if (displayValue !== undefined && displayValue !== null) {
                    uniqueValues.add(displayValue.toString());
                }
            });
            values[col.key] = Array.from(uniqueValues);
        });
        return values;
    }, [filteredData, columns, valueMappings]);

    const visibleRowCount = filteredData.length;

    const handleSort = (key) => {
        if (sortKey === key) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortKey(key);
            setSortOrder('asc');
        }
    };

    const toggleColumnFilter = (columnKey, value) => {
        setColumnFilters((prevFilters) => {
            const currentFilter = prevFilters[columnKey] || [];
            const newFilter = currentFilter.includes(value)
                ? currentFilter.filter((item) => item !== value)
                : [...currentFilter, value];
            return { ...prevFilters, [columnKey]: newFilter };
        });
    };

    const renderCellContent = (row, column) => {
        if (column === 'configuration_status') {
            return getPropertiesConfigurationStatus(row.custom_properties, row.vertical_id);
        }
        const content = getNestedValue(row, column);
        if (configuredFields.includes(column) && content) {
            return '✓';
        }

        if (configuredFields.includes(column) && !content) {
            return '𐄂';
        }
        if (valueMappings[column] && valueMappings[column][content] !== undefined) {
            return valueMappings[column][content];
        }
        if (typeof content === 'object' && content !== null) {
            return JSON.stringify(content);
        }
        return content;
    };

    return (
        <div>
            <div className="header-controls d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center" style={{ width: "500px" }}>
                    <input
                        type="text"
                        className="form-control me-2"
                        placeholder="Search... (Use :missing to filter missing data)"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                    <div className="index-display ms-2 d-flex align-items-center">
                        <FiHash className="me-1" />
                        <span>{visibleRowCount}</span>
                    </div>
                </div>

                <Button onClick={handleShowAddSupplierModal} className="btn btn-warning">Add</Button>
            </div>
            <EditSupplierModal
                supplier={selectedSupplier}
                availableCustomProperties={availablePropertiesByVertical}
                show={showEditSupplierModal}
                setSupplier={setSelectedSupplier}
                handleClose={handleCloseEditSupplierModal}
                handleSave={handleUpdateSupplier}
                uploadFile={uploadFile}
                loading={loading}
            />
            <AddSupplierModal
                availableCustomProperties={availablePropertiesByVertical}
                show={showAddSupplierModal}
                handleClose={handleCloseAddSupplierModal}
                handleCreateSupplier={handleCreateSupplier}
                uploadFile={uploadFile}
                loading={loading}
                availableVerticals={verticals}
            />
            <div className="table-scroll-container">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            {columns.map((col) => (
                                <th key={col.key} className="sticky-header">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleSort(col.key)}
                                        >
                                            {col.displayName || col.key}
                                            {sortKey === col.key ? (sortOrder === 'asc' ? ' ▲' : ' ▼') : ''}
                                        </span>
                                        {toggleableColumns.includes(col.key) && (
                                            <Dropdown className="dropdown-supplier-table" drop="down-centered" align="end">
                                                <Dropdown.Toggle variant="light" size="sm" id={`dropdown-${col.key}`}>
                                                    ⚙
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {uniqueDropdownValues[col.key].map((value) => (
                                                        <Dropdown.Item key={value} as="div">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={`filter-${col.key}-${value}`}
                                                                    checked={(columnFilters[col.key] || []).includes(value)}
                                                                    onChange={() => toggleColumnFilter(col.key, value)}
                                                                />
                                                                <label className="form-check-label" htmlFor={`filter-${col.key}-${value}`}>
                                                                    {value}
                                                                </label>
                                                            </div>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {columns.map((col) =>
                                    <td
                                        key={col.key}
                                        style={{ width: columnWidths[col.key] || '150px' }}
                                        onClick={() => handleShowEditSupplierModal(row)}
                                    >
                                        {renderCellContent(row, col.key)}
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SuppliersPage;
