import React from 'react';
import { FaChevronRight, FaChevronDown, FaTrash } from 'react-icons/fa';
import { LuMenuSquare } from 'react-icons/lu';
import { AiOutlineFunction } from 'react-icons/ai';

const Layers = ({
  item,
  level,
  isRoot,
  setSelectedMenu,
  setFunction,
  functionExists,
  selectedMenuKey,
  setSelectedMenuKey,
  expandedStates,
  setExpandedStates,
  deleteMenuItem
}) => {
  if (!item) {
    return null; // Safeguard for undefined item
  }

  // Ensure the item has a unique key. You can also use something more deterministic if needed.
  const itemKey = item.key;

  // Safely access expandedStates to prevent accessing properties of null
  const isExpanded = isRoot ? (expandedStates && itemKey in expandedStates ? expandedStates[itemKey] : true)
    : (expandedStates && itemKey in expandedStates ? expandedStates[itemKey] : false);

  const toggleExpand = (e) => {
    e.stopPropagation(); // Prevent the toggleSelect event from firing when clicking the chevron
    setExpandedStates((prev) => ({
      ...prev,
      [itemKey]: !isExpanded,
    }));
    setSelectedMenu(item);
    setSelectedMenuKey(itemKey);
  };

  const toggleSelect = () => {
    setExpandedStates((prev) => ({
      ...prev,
      [itemKey]: true, // Always expand the item when selected
    }));
    setSelectedMenu(item);
    setSelectedMenuKey(itemKey);
  };

  return (
    <div style={{ paddingLeft: level * 20, position: 'relative' }}>
      {(isRoot || item.sub_menus) && (
        <div
          onClick={toggleSelect}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            padding: '5px',
            borderRadius: '4px',
          }}
        >
          <div onClick={toggleExpand} style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            {isExpanded ? <FaChevronDown className='fa-icon' /> : <FaChevronRight className='fa-icon' />}
            {isExpanded && (
              <div
                style={{
                  position: 'absolute',
                  top: '24px',
                  left: '9px',
                  width: '2px',
                  height: 'calc(100% - 24px)',
                  backgroundColor: '#ccc',
                }}
              ></div>
            )}
            {isExpanded && (
              <div
                style={{
                  position: 'absolute',
                  top: '12px',
                  left: '12px',
                  width: 'calc(100% - 20px)',
                  height: '2px',
                  backgroundColor: '#ccc',
                }}
              ></div>
            )}
          </div>
          <LuMenuSquare className='fa-icon' style={{ marginLeft: '5px' }} />
          &nbsp;
          <small className={`${itemKey === selectedMenuKey ? 'bg-warning' : 'bg-light'}`}>{item.title}</small>
          {!isRoot && (
            <FaTrash
              style={{ marginLeft: 'auto', cursor: 'pointer' }}
              className='fa-icon'
              onClick={() => deleteMenuItem(itemKey)}  // Call delete function when clicked
            />
          )}
        </div>
      )}
      {isExpanded && item.sub_menus && (
        <div style={{ borderLeft: '2px solid #ccc', marginLeft: '12px', paddingLeft: '8px' }}>
          {Object.entries(item.sub_menus).map(([key, subItem]) => (
            <Layers
              key={key}
              item={subItem}
              level={level + 1}
              setSelectedMenu={setSelectedMenu}
              setFunction={setFunction}
              functionExists={functionExists}
              selectedMenuKey={selectedMenuKey}
              setSelectedMenuKey={setSelectedMenuKey}
              expandedStates={expandedStates}
              setExpandedStates={setExpandedStates}
              deleteMenuItem={deleteMenuItem} // Pass down delete function
            />
          ))}
        </div>
      )}
      {isExpanded &&
        item.sub_menus &&
        Object.entries(item.sub_menus).map(
          ([key, subItem]) =>
            subItem.is_function &&
            functionExists(key) && (
              <div
                key={key}
                style={{
                  paddingLeft: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  paddingRight: '5px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setFunction(key);
                  const matchedMenu = item.sub_menus[key];
                  setSelectedMenu(matchedMenu || null); // Use `null` as a fallback if no match is found
                  setSelectedMenuKey(key); // Set selectedMenuKey to highlight the function
                }}
              >
                <AiOutlineFunction className='fa-icon' style={{ marginRight: '5px' }} />
                <small className={`${key === selectedMenuKey ? 'bg-warning' : 'bg-light'}`}>
                  {subItem.title}
                </small>
                <FaTrash
                  style={{ marginLeft: 'auto', cursor: 'pointer' }}
                  className='fa-icon'
                  onClick={() => deleteMenuItem(key)}  // Call delete function for functions as well
                />
              </div>
            )
        )}
    </div>
  );
};

export default Layers;
