import { useState } from "react";
import Verticals from './Verticals';
import LeftPillar from './LeftPillar';
import NewsCarousel from './NewsCarousel';
import { Container, Spinner, Alert, Button } from "react-bootstrap";
import SuppliersPage from './SuppliersPage';
import ApiHost from './ApiHost';
import ConfirmModal from "./ConfirmModal";
import { deepMerge } from "./Utils";

const Page = ({
  setMenuData,
  selectedMenu,
  selectedSection,
  menuData,
  setSelectedMenu,
  verticals,
  loading,
  error,
  handleSetLayer,
  newsItems,
  selectedMenuKey,
  setSelectedMenuKey,
  selectedFunction,
  setSelectedFunction,
  handleUndo,
  handleRedo,
  undoStack,
  setUndoStack,
  redoStack,
  setRedoStack,
  hasChanges,
  setHasChanges,
  copyMenuItem,
  pasteMenuItem,
  clipboard,
  quillRef,
  suppliers,
  jwtToken,
  environment
}) => {
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  // Show spinner while loading
  if (loading) {
    return (
      <Container className="text-center my-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  // Show error message if there's an error
  if (error) {
    return (
      <Container className="text-center my-5">
        <Alert variant="danger">
          <strong>Oops!</strong> Something went wrong. Unable to load data.
          Please try again later.
        </Alert>
      </Container>
    );
  }
  const handleSetMenuData = (newMenuData) => {
    // Save the current state to the undo stack before making changes
    setUndoStack((prevUndoStack) => [...prevUndoStack, menuData]);
    setMenuData(newMenuData);
    setHasChanges((prevHasChanges) => {
      return prevHasChanges+1;
    }); // Indicate that changes have occurred

    // Clear the redo stack on a new change
    setRedoStack([]);
  };

  const handlePublishVerticals = async () => {
    // Iterate over each object in `verticals`
    verticals.forEach(vertical => {
      // Check if this vertical's ID matches menuData's ID
      if (vertical.id === menuData.id) {
        // Merge menuData properties into this vertical object
        deepMerge(vertical, menuData);
      }
    });

    try {
      let queryString = environment === 'Staging' ? 'staging=1' : '';
      const response = await fetch(`${ApiHost}/v1/update-verticals?${queryString}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`, // Pass the token in the Authorization header
        },
        body: JSON.stringify(verticals),
      });

      if (response.ok) {
        setLoadingPublish(false);
        console.log('Verticals updated successfully');
        setHasChanges(0);
      } else {
        console.error('Failed to update verticals');
      }
    } catch (error) {
      console.error('Error updating verticals:', error);
    }
  };

  const createValueMappingsFromVerticals = (verticalsArray, columnName) => {
    const mapping = verticalsArray.reduce((acc, item) => {
      acc[item.id] = item.title; // Map ID to key or name
      return acc;
    }, {});

    return {
      [columnName]: mapping,
    };
  };

  const getCustomPropertiesFromVertical = (verticalsArray) => {
    return verticalsArray.reduce((acc, item) => {
      acc[item.id] = item.available_custom_properties; // Map ID to custom properties
      return acc;
    }, {});
  };

  const handlePublish = () => {
    setLoadingPublish(true);
    setShowPublishConfirm(true)
  };
  const handlePublishConfirm = () => {
    handlePublishVerticals();
    setShowPublishConfirm(false);
  };
  const handlePublishCancel = () => {
    setLoadingPublish(false);
    setShowPublishConfirm(false);
  };

  return (
    <div className="PageContainer row m-0 p-0">
      {selectedSection === 'Verticals' && (
        <>
          <div className="p-0" style={{ width: "20%" }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid #ccc', position: 'relative', padding: '1rem' }}>
              <h4 className='m-0'>Verticals</h4>
              <div style={{ position: 'absolute', right: '1rem', display: 'flex', alignItems: 'center', gap: '8px' }}>
                {hasChanges > 0 && (
                  <div className="text-center">
                    <ConfirmModal
                      show={showPublishConfirm}
                      onConfirm={handlePublishConfirm}
                      onCancel={handlePublishCancel}
                      message={`Are you sure you want to publish to ${environment}.`}
                    />
                    <Button disabled={loadingPublish} onClick={handlePublish} className="btn btn-sm text-white bg-success position-relative">
                      {loadingPublish ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Publish'
                      )}
                      <span
                        className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                        style={{ fontSize: '0.6rem', padding: '0.3em 0.5em' }}
                      >
                        {hasChanges}
                      </span>
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="p-3">
              <LeftPillar verticals={verticals} loading={loading} error={error} handleSetLayer={handleSetLayer} />
            </div>
          </div>
          <div className="p-0" style={{ width: "80%", height: "100%" }}>
            <div className="Page">
              <Verticals
                setMenuData={handleSetMenuData}
                selectedMenu={selectedMenu}
                setSelectedMenu={setSelectedMenu}
                menuData={menuData}
                selectedMenuKey={selectedMenuKey}
                setSelectedMenuKey={setSelectedMenuKey}
                selectedFunction={selectedFunction}
                setSelectedFunction={setSelectedFunction}
                handleUndo={handleUndo}
                handleRedo={handleRedo}
                hasChanges={hasChanges}
                undoStack={undoStack}
                redoStack={redoStack}
                setUndoStack={setUndoStack}
                setRedoStack={setRedoStack}
                copyMenuItem={copyMenuItem}
                pasteMenuItem={pasteMenuItem}
                clipboard={clipboard}
                quillRef={quillRef}
                loading={loading}
                error={error}
                verticalsData={verticals}
              />
            </div>
          </div>
        </>
      )}
      {selectedSection === 'Suppliers' && (
        <div className="p-4">
          <SuppliersPage
            data={suppliers}
            columns={[
              {
                key: 'name',
                displayName: 'Name'
              },
              {
                key: 'vertical_id',
                displayName: 'Vertical'
              },
              {
                key: 'address.city',
                displayName: 'Area'
              },
              {
                key: 'phone_number',
                displayName: 'Phone Number'
              },
              {
                key: 'website_url',
                displayName: 'Website'
              },
              {
                key: 'logo_url',
                displayName: 'Logo'
              },
              {
                key: 'configuration_status',
                displayName: "Custom Properties"
              }
            ]}
            columnWidths={{ 'name': '150px', 'website_url': '150px' }}
            missingFields={['phone_number', 'logo_url']}
            configuredFields={['website_url', 'logo_url', 'phone_number']}
            verticals={verticals}
            valueMappings={createValueMappingsFromVerticals(verticals, 'vertical_id')}
            toggleableColumns={['vertical_id', 'address.city']}
            availablePropertiesByVertical={getCustomPropertiesFromVertical(verticals)}
            environment={environment}
            jwtToken={jwtToken}
          />
        </div>
      )}
      {selectedSection === 'News' && <div><NewsCarousel news={newsItems} /></div>}
    </div>
  );
};

export default Page;