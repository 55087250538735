// LeftPillar.js
import React from "react";
import { Container, Row, Card } from "react-bootstrap";

const LeftPillar = ({ verticals, handleSetLayer }) => {
  return (
    <Container className="my-3 text-center LeftPillar">
        <Row>
          {verticals
            .sort((a, b) => a.order - b.order)
            .map((card) => (
              <Row key={card.key} md={2}>
                <Card
                  className="p-1"
                  style={{
                    borderRadius: "25px",
                    border: "0",
                    color: "#030765",
                    width: "100%"
                  }}
                  onClick={() => handleSetLayer(card)}
                >
                  <Card.Img
                    variant="top"
                    src={card.icon_url}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "25px",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Row>
            ))}
        </Row>
      </Container>
  );
};

export default LeftPillar;