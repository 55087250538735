// Utils.js

// Function to deeply clone an object using JSON methods
export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

// Define the icon sorting order
const iconSortOrder = {
  '✔': 1,          // Configured
  '➖': 2,          // Partially Configured (or your chosen symbol)
  '𐄂': 3,          // Not Configured
};

export const sortData = (data, sortKey, sortOrder) => {
  return [...data].sort((a, b) => {
    const aVal = getNestedValue(a, sortKey);
    const bVal = getNestedValue(b, sortKey);

    // Handle undefined values to always appear at the end of the sorted list
    if (aVal === undefined) return sortOrder === 'asc' ? 1 : -1;
    if (bVal === undefined) return sortOrder === 'asc' ? -1 : 1;

    // Map icons to their respective sorting values if they exist in the map
    const aMapped = iconSortOrder[aVal] !== undefined ? iconSortOrder[aVal] : aVal.toString();
    const bMapped = iconSortOrder[bVal] !== undefined ? iconSortOrder[bVal] : bVal.toString();

    // Compare mapped values (numbers for icons or strings for other types)
    if (aMapped < bMapped) return sortOrder === 'asc' ? -1 : 1;
    if (aMapped > bMapped) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });
};

// Helper function to get nested values using dot notation (e.g., 'address.city')
export const getNestedValue = (obj, path) => {
  return path.split('.').reduce((o, p) => (o ? o[p] : undefined), obj);
};

export const deepMerge = (target, source) => {
  for (const key in target) {
    // If a key in target is not present in source, delete it from target
    if (!(key in source)) {
      delete target[key];
    }
  }

  for (const key in source) {
    if (source[key] === undefined) {
      // If the source has a key explicitly set to undefined, delete it from target
      delete target[key];
    } else if (Array.isArray(source[key])) {
      // If the source has an array, overwrite the target array entirely
      target[key] = source[key].slice(); // Copy the array to avoid mutation
    } else if (source[key] instanceof Object && key in target) {
      // If both source and target have an object, recursively merge them
      target[key] = deepMerge(target[key], source[key]);
    } else {
      // Otherwise, directly assign the value from source to target
      target[key] = source[key];
    }
  }
  return target;
}

export const computeSHA256Hash = async (file) => {
  const arrayBuffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
};