import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './NewsCarousel.css'
import './SuppliersPage.css';
import './EditSupplierModal.css'
import './AddSupplierModal.css'
import msalInstance from "./msalConfig";
import { MsalProvider } from "@azure/msal-react";

const root = ReactDOM.createRoot(document.getElementById("root")); // Create root for React 18

// Initialize msalInstance if needed, then render
msalInstance.initialize().then(() => {
    root.render(
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    );
}).catch(error => {
    console.error("MSAL Initialization Error:", error);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
