import { PublicClientApplication } from "@azure/msal-browser";
const environment = process.env.REACT_APP_ENVIRONMENT || null;
const msalConfig = {
    auth: {
        clientId: '8d227b29-7e91-4645-b89c-0e2a171a691e', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.driverhq.co.uk/7b965fa9-b85b-4689-ad05-14b6479eddaa', // Replace the placeholder with your tenant subdomain 
        redirectUri: environment === 'production' ? 'https://portal.driverhq.co.uk/' : 'http://localhost:3000',
        knownAuthorities: ["login.driverhq.co.uk"]
    },
    cache: {
        cacheLocation: "localStorage", // Optional
        storeAuthStateInCookie: true,
    },
    system: {
        navigateToLoginRequestUrl: false, // To prevent redirection to the original URL
        instanceDiscoveryEnabled: false // Disables instance discovery
    },
};

// Define the scope
export const apiRequest = {
    scopes: [], // Replace YOUR_APP_ID with the Function App's App ID
};

const msalInstance = new PublicClientApplication(msalConfig);
export default msalInstance;