import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from "quill";
const BlockEmbed = Quill.import("blots/block/embed");

// register Quill divider Blot:
class DividerBlot extends BlockEmbed {
  static blotName = "divider";
  static tagName = "hr";
}

Quill.register(DividerBlot);

// post-process function for Quill instances:
const postProcessQuill = (quill) => {
  const toolbar = quill.getModule("toolbar");
  const divider = toolbar.container?.querySelector(".ql-divider");

  if (divider) {
    divider.innerHTML = `
      <svg viewBox="0 0 18 18">
        <line class="ql-stroke" x1="1.795" y1="9" x2="16.205" y2="9"/>
        <polyline class="ql-stroke" points="13.494,4.706 13.494,6.239 4.506,6.239 4.506,4.706 "/>
        <polyline class="ql-stroke" points="13.494,13.295 13.494,11.76 4.506,11.76 4.506,13.295 "/>
      </svg>
    `;
  }

  toolbar.addHandler("divider", () => {
    const range = quill.getSelection(true);
    const prev = quill.getText({ index: range.index - 1, length: 1 });
    const next = quill.getText({ index: range.index, length: 1 });

    if (prev !== "\n" && prev !== "") {
      quill.insertText(range.index++, "\n", "user");
    }
    quill.insertEmbed(range.index++, "divider", true, "user");
    if (next === "\n") {
      quill.deleteText(range.index, 1, "user");
    }

    quill.setSelection(range.index, 0, "user");
  });
};

// Editor is an uncontrolled React component
const Editor = forwardRef(
  ({ readOnly, defaultValue, onTextChange, onSelectionChange }, ref) => {
    const containerRef = useRef(null);
    const quillRef = useRef(null);

    // Update refs to always have the latest callback functions
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      // Initialize Quill editor
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div')
      );

      const toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],        // toggled buttons
        ['link', 'divider'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme      
        ['clean']                                         // remove formatting button
      ];

      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: {
          clipboard: true,
          toolbar: toolbarOptions
        },
      });

      postProcessQuill(quill);
      quillRef.current = quill;

      // Set ref to Quill instance
      if (ref) {
        ref.current = quill;
      }

      let ignoreChangeEvent = true; // Set to true initially to ignore changes

      // Set the initial content if available
      if (defaultValue && typeof defaultValue === 'string') {
        quill.clipboard.dangerouslyPasteHTML(defaultValue);
      }

      const handleTextChange = () => {
        if (ignoreChangeEvent) {
          ignoreChangeEvent = false; // Only ignore the first event after initialization
        } else {
          const htmlContent = quill.root.innerHTML;
          if (onTextChangeRef.current) {
            onTextChangeRef.current(htmlContent);
          }
        }
      };

      // Register event listeners
      quill.on(Quill.events.TEXT_CHANGE, handleTextChange);

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      // Handle pasting explicitly
      const handlePaste = (e) => {
        setTimeout(() => {
          handleTextChange(); // Manually trigger after Quill processes the paste
        }, 0);
      };

      const editorElement = quill.root;
      editorElement.addEventListener('paste', handlePaste);

      // Cleanup
      return () => {
        if (ref) {
          ref.current = null;
        }
        quill.off(Quill.events.TEXT_CHANGE);
        quill.off(Quill.events.SELECTION_CHANGE);
        editorElement.removeEventListener('paste', handlePaste);
        quillRef.current = null;
        container.innerHTML = '';
      };
    }, [ref, defaultValue]);

    // Effect to update Quill's readOnly property if it changes
    useEffect(() => {
      if (quillRef.current) {
        quillRef.current.enable(!readOnly);
      }
    }, [readOnly]);

    // Effect to update Quill content when `defaultValue` changes externally
    useEffect(() => {
      if (quillRef.current && defaultValue && typeof defaultValue === 'string') {
        const currentContent = quillRef.current.root.innerHTML;
        if (currentContent !== defaultValue) {
          quillRef.current.clipboard.dangerouslyPasteHTML(defaultValue);
        }
      }
    }, [defaultValue]);

    return <div ref={containerRef}></div>;
  }
);

Editor.displayName = 'Editor';

export default Editor;
