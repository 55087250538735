import React from 'react';

const Pages = ({ setSelectedSection, selectedSection }) => {
  const handleSelection = (section) => {
    setSelectedSection(section);
  };

  return (
    <div className="Pages d-flex text-center w-100">
      <div
        onClick={() => handleSelection('Verticals')}
        className={`p-3 flex-fill ${selectedSection === 'Verticals' ? 'bg-warning' : 'bg-light'}`}
        style={{ cursor: 'pointer', borderRight: "1px solid #ccc", borderBottom: "1px solid #ccc"}}
      >
        Verticals
      </div>
      <div
        onClick={() => handleSelection('Suppliers')}
        className={`p-3 flex-fill ${selectedSection === 'Suppliers' ? 'bg-warning' : 'bg-light'}`}
        style={{ cursor: 'pointer', borderRight: "1px solid #ccc", borderBottom: "1px solid #ccc"}}
      >
        Suppliers
      </div>
      <div
        onClick={() => handleSelection('News')}
        className={`p-3 flex-fill ${selectedSection === 'News' ? 'bg-warning' : 'bg-light'}`}
        style={{ cursor: 'pointer', borderRight: "1px solid #ccc", borderBottom: "1px solid #ccc"}}
      >
        News
      </div>
    </div>
  );
};

export default Pages;
