import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddMenuModal = ({ show, handleClose, handleAddMenu, parentKey }) => {
    const [menuName, setMenuName] = useState('');

    // Handle input change for menu name
    const handleInputChange = (e) => {
        setMenuName(e.target.value);
    };

    // Handle form submission to add the new menu
    const handleSubmit = () => {
        // Convert the menu name to lowercase and replace spaces with underscores
        const key = parentKey + "|" + menuName.toLowerCase().replace(/ /g, '_');

        // Prepare the new menu item
        const newMenuItem = {
            key: key,
            title: menuName,
            layout: "horizontal",
            content: "What we say here has not been decided yet.",
            sub_menus: {},
            options: []
        };

        // Use the provided handler to add the menu
        handleAddMenu(parentKey, newMenuItem);

        // Reset the form and close the modal
        setMenuName('');
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formMenuName">
                        <Form.Label>Menu Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter menu name"
                            value={menuName}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className='custom-btn-primary' onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="warning" onClick={handleSubmit} disabled={!menuName}>
                    Add Menu
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddMenuModal;