import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmModal = ({ show, onConfirm, onCancel, message }) => (
    <Modal show={show} onHide={onCancel} centered>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{message || "Are you sure you want to proceed with this action?"}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={onCancel}>
                Cancel
            </Button>
            <Button variant="danger" onClick={onConfirm}>
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ConfirmModal;