import React, { useState, useEffect } from 'react';
import Layers from './Layers';
import 'react-quill/dist/quill.snow.css';
import { FaUndo, FaRedo, FaFile, FaCopy, FaPaste, FaPlus, FaTrash } from 'react-icons/fa';
import { faMapMarkerAlt, faCar, faGlobe, faPhone, faTag, faCalendarDays, faAward, faSterlingSign } from '@fortawesome/free-solid-svg-icons';
import { faClipboard, faIdBadge, faStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { deepClone } from './Utils';
import AddMenuModal from './NewMenuModal';
import { ButtonGroup, Button } from 'react-bootstrap';
import Editor from './Editor';

const Verticals = ({
    selectedMenu,
    menuData,
    setSelectedMenu,
    selectedMenuKey,
    setSelectedMenuKey,
    selectedFunction,
    setSelectedFunction,
    setMenuData,
    handleUndo,
    handleRedo,
    undoStack,
    redoStack,
    setUndoStack,
    setRedoStack,
    copyMenuItem,
    pasteMenuItem,
    clipboard,
    quillRef
}) => {
    const [expandedStates, setExpandedStates] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [activeContent, setActiveContent] = useState(menuData && menuData.html_content != null ? 'html_content' : 'content'); // Default to 'content'

    // Mapping FontAwesome icons
    const iconMap = {
        faMapMarkerAlt: faMapMarkerAlt,
        faCar: faCar,
        faGlobe: faGlobe,
        faPhone: faPhone,
        faIdBadge: faIdBadge,
        faTag: faTag,
        faClipboard: faClipboard,
        faStar: faStar,
        faCalendarDays: faCalendarDays,
        faAward: faAward,
        faSterlingSign: faSterlingSign
    };

    const iconOptions = Object.entries(iconMap).map(([key, icon]) => ({
        value: key,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={icon} style={{ marginRight: 8 }} />
                {key}
            </div>
        ),
    }));

    useEffect(() => {
        setActiveContent(selectedMenu && selectedMenu.html_content != null ? 'html_content' : 'content');
    },[selectedMenu]);

    // Function to handle switching content type and setting the unselected field to null
    const handleContentSwitch = (type) => {
        setActiveContent(type);

        // Set the opposite field to null when switching
        if (type === 'content') {
            handleInputChangeInData('html_content', null);
            handleInputChangeInData('content', "");
        } else if (type === 'html_content') {
            handleInputChangeInData('html_content', "");
            handleInputChangeInData('content', null);
        }
    };
    // Function to handle showing the modal
    const handleShowModal = () => {
        setShowModal(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    // Function to handle adding the menu
    const handleAddMenu = (parentKey, newMenuItem) => {
        // Assuming `addMenuItem` is the function to add a menu item
        // Pass null or appropriate `parentKey` to add the item to the correct place
        addMenuItem(parentKey, newMenuItem); // Here adding at root level; modify as needed
    };

    const setSelectedMenuOverride = (menu) => {
        if (!menu.is_function) {
            setSelectedFunction(null);
        }

        setSelectedMenu(menu);
    };

    const setFunction = (key) => {
        if (menuData.functions?.search_suppliers_functions) {
            const tmp = menuData.functions.search_suppliers_functions.find(option => option.button_id === key);

            if (tmp) {
                setSelectedFunction(tmp);
                setSelectedMenu(null);
            }
        }

        if (menuData.functions?.ask_us_a_question_functions) {
            const tmp = menuData.functions.ask_us_a_question_functions.find(option => option.button_id === key);

            if (tmp) {
                setSelectedFunction(tmp);
                setSelectedMenu(null);
            }
        }
    };

    const functionExists = (key) => {
        if (menuData.functions?.search_suppliers_functions) {
            let found = menuData.functions.search_suppliers_functions.find(option => option.button_id === key);

            if (found) {
                return found;
            }
        }

        if (menuData.functions?.ask_us_a_question_functions) {
            let found = menuData.functions.ask_us_a_question_functions.find(option => option.button_id === key);

            if (found) {
                return found;
            }
        }

        return null;
    };

    // Function to add a new menu item to `menuData`
    const addMenuItem = (parentKey, newItem) => {
        // Save the current state to the undo stack before making changes
        setUndoStack((prevUndoStack) => [...prevUndoStack, deepClone(menuData)]);

        // Clear the redo stack since we are making a new change
        setRedoStack([]);

        setMenuData((prevMenuData) => {
            let updatedData = deepClone(prevMenuData); // Clone to avoid mutation issues

            // Recursive function to add the new item
            const addRecursively = (items) => {
                if (!items) return items;

                const updatedItems = { ...items };

                // Traverse the items to find the correct parent where newItem should be added
                Object.keys(updatedItems).forEach((currentKey) => {
                    if (currentKey === parentKey) {
                        // Add the new item to the sub_menus of the parent
                        if (!updatedItems[currentKey].sub_menus) {
                            updatedItems[currentKey].sub_menus = {};
                        }
                        updatedItems[currentKey].sub_menus[newItem.key] = newItem;

                        // Update the options array of the parent to reference the new item
                        if (!updatedItems[currentKey].options) {
                            updatedItems[currentKey].options = [];
                        }
                        updatedItems[currentKey].options.push({
                            key: newItem.key,
                            value: newItem.title,
                        });
                    } else if (updatedItems[currentKey].sub_menus) {
                        // Recursively search in sub_menus
                        updatedItems[currentKey].sub_menus = addRecursively(updatedItems[currentKey].sub_menus);
                    }
                });

                return updatedItems;
            };

            // Add at the root level if parentKey is null
            if (parentKey === updatedData.key) {
                updatedData.sub_menus = updatedData.sub_menus || {};
                updatedData.sub_menus[newItem.key] = newItem;

                if (!updatedData.options) {
                    updatedData.options = [];
                }
                updatedData.options.push({
                    key: newItem.key,
                    value: newItem.title,
                });
            } else {
                // Otherwise, add to the correct parent within sub_menus
                if (updatedData.sub_menus) {
                    updatedData.sub_menus = addRecursively(updatedData.sub_menus);
                }
            }

            return updatedData;
        });

        // Optionally, set `selectedMenu` to the new item to make it immediately selectable
        setSelectedMenu(newItem);
        setSelectedMenuKey(newItem.key);
    };

    // Function to delete a menu item, function, or an item from options in `menuData`
    const deleteMenuItem = (key) => {
        // Save the current state to the undo stack before proceeding with the delete
        setUndoStack((prevUndoStack) => [...prevUndoStack, deepClone(menuData)]);

        // Clear the redo stack since we are making a new change
        setRedoStack([]);

        // Update the menu data with the deleted item removed
        setMenuData((prevMenuData) => {
            let updatedData = deepClone(prevMenuData); // Clone to avoid mutation issues

            // Recursive function to delete the item
            const deleteRecursively = (items, parentItem = null) => {
                if (!items) return items;

                // Create a new object excluding the key to be deleted
                const updatedItems = Object.keys(items).reduce((acc, currentKey) => {
                    if (currentKey !== key) {
                        acc[currentKey] = { ...items[currentKey] };

                        // Remove key from options if it exists
                        if (acc[currentKey].options) {
                            acc[currentKey].options = acc[currentKey].options
                                .filter(option => option && option.key !== key);

                            // If all options were removed, set options to an empty array
                            if (acc[currentKey].options.length === 0) {
                                acc[currentKey].options = [];
                            }
                        }

                        // Continue to process sub_menus if they exist
                        if (acc[currentKey].sub_menus) {
                            acc[currentKey].sub_menus = deleteRecursively(
                                acc[currentKey].sub_menus,
                                acc[currentKey] // Pass the current item as the parent for nested items
                            );
                        }
                    } else {
                        // If the current key is the one being deleted, we need to determine how to handle selection
                        if (selectedMenuKey === key) {
                            if (parentItem) {
                                // Set the parent if there is one
                                setSelectedMenu(parentItem);
                                setSelectedMenuKey(parentItem.key);
                            } else {
                                const siblingKeys = Object.keys(items).filter(k => k !== key);
                                if (siblingKeys.length > 0) {
                                    const newSelectedKey = siblingKeys[0];
                                    setSelectedMenu(items[newSelectedKey]);
                                    setSelectedMenuKey(newSelectedKey);
                                } else {
                                    setSelectedMenu(menuData);
                                    setSelectedMenuKey(menuData.key);
                                }
                            }
                        }
                    }

                    return acc;
                }, {});

                return updatedItems;
            };

            // Delete functions if it matches the key
            if (updatedData.functions?.search_suppliers_functions) {
                updatedData.functions.search_suppliers_functions = updatedData.functions.search_suppliers_functions.filter(
                    (func) => func.button_id !== key
                );

                // Clear selected function if it was deleted
                if (selectedFunction?.button_id === key) {
                    setSelectedFunction(null);
                }
            }

            if (updatedData.functions?.ask_us_a_question_functions) {
                updatedData.functions.ask_us_a_question_functions = updatedData.functions.ask_us_a_question_functions.filter(
                    (func) => func.button_id !== key
                );

                // Clear selected function if it was deleted
                if (selectedFunction?.button_id === key) {
                    setSelectedFunction(null);
                }
            }

            // Remove key from options if it exists
            updatedData.options = updatedData.options
                .filter(option => option && option.key !== key);

            // If all options were removed, set options to an empty array
            if (updatedData.options.length === 0) {
                updatedData.options = [];
            }

            // Delete menu items and options recursively
            if (updatedData.sub_menus) {
                updatedData.sub_menus = deleteRecursively(updatedData.sub_menus);
            }

            return updatedData;
        });
    };

    // Function to update a specific field in `menuData` by recursively finding the target item
    const handleInputChangeInData = (key, value, dontUpdateSelectedMenu = false) => {
        const updateRecursively = (items, parent = null) => {
            if (!items) return items;

            const updatedItems = Object.keys(items).reduce((acc, currentKey) => {
                acc[currentKey] = { ...items[currentKey] };

                // Check if this is the selected item, then update it
                if (currentKey === selectedMenuKey) {
                    acc[currentKey][key] = value; // Update the specific key with the new value

                    // If updating the title, also update corresponding entries in parent's options
                    if (key === "title" && parent && parent.options) {
                        parent.options = parent.options.map((option) => {
                            if (option.key === selectedMenuKey) {
                                return {
                                    ...option,
                                    value: value, // Update the value in the parent's options
                                };
                            }
                            return option;
                        });
                    }
                }

                // Continue to process sub_menus if they exist
                if (acc[currentKey].sub_menus) {
                    acc[currentKey].sub_menus = updateRecursively(acc[currentKey].sub_menus, acc[currentKey]);
                }

                return acc;
            }, {});

            return updatedItems;
        };

        setMenuData((prevMenuData) => {
            let updatedData = { ...prevMenuData };

            // Update the root item if it matches the selected menu key
            if (updatedData.key === selectedMenuKey) {
                updatedData[key] = value;

                // If updating the title, also update corresponding entries in root options
                if (key === "title" && updatedData.options) {
                    updatedData.options = updatedData.options.map((option) => {
                        if (option.key === selectedMenuKey) {
                            return {
                                ...option,
                                value: value, // Update the value in root options
                            };
                        }
                        return option;
                    });
                }
            } else if (updatedData.sub_menus) {
                // Update nested submenus if they exist
                updatedData.sub_menus = updateRecursively(updatedData.sub_menus, updatedData);
            }

            return updatedData;
        });

        // Update selected menu as well to reflect changes instantly in the form
        if (selectedMenu && !dontUpdateSelectedMenu) {
            setSelectedMenu((prevSelectedMenu) => ({
                ...prevSelectedMenu,
                [key]: value,
            }));
        }
    };

    // Function to handle changes to the custom properties, updating key or value
    const handleAvailableCustomPropertyChange = (index, field, newValue) => {
        setMenuData((prevMenu) => {
            const updatedProperties = prevMenu.available_custom_properties.map((prop, i) => {
                if (i === index) {
                    return {
                        ...prop,
                        [field]: newValue // Update either 'key' or 'value' field
                    };
                }
                return prop;
            });
            const updatedMenu = { ...prevMenu, available_custom_properties: updatedProperties };

            // Update selectedMenu immediately after changing menuData
            setSelectedMenu(updatedMenu);

            return updatedMenu;
        });
    };

    // Function to add a new custom property
    const handleAddCustomProperty = () => {
        setMenuData((prevMenu) => {
            const updatedMenu = {
                ...prevMenu,
                available_custom_properties: [
                    ...prevMenu.available_custom_properties,
                    { key: "", value: "string" } // Default new property with 'string' type
                ]
            };

            // Update selectedMenu immediately after adding to menuData
            setSelectedMenu(updatedMenu);

            return updatedMenu;
        });
    };

    // Function to remove a custom property by index
    const handleRemoveAvailableCustomProperty = (index) => {
        setMenuData((prevMenu) => {
            const updatedMenu = {
                ...prevMenu,
                available_custom_properties: prevMenu.available_custom_properties.filter((_, i) => i !== index)
            };

            // Update selectedMenu immediately after removing from menuData
            setSelectedMenu(updatedMenu);

            return updatedMenu;
        });
    };

    // Render custom properties with key input and type dropdown
    const renderCustomProperties = () => {
        return selectedMenu.available_custom_properties.map((prop, index) => (
            <div key={index} className="mb-3 d-flex align-items-center">
                {/* Input for the key */}
                <input
                    type="text"
                    className="form-control me-3"
                    value={prop.key}
                    placeholder="Enter prop key no spaces"
                    onChange={(e) => handleAvailableCustomPropertyChange(index, 'key', e.target.value)}
                />

                {/* Dropdown for the value type */}
                <select
                    className="form-select me-3"
                    value={prop.value}
                    onChange={(e) => handleAvailableCustomPropertyChange(index, 'value', e.target.value)}
                >
                    <option value="string">Text</option>
                    <option value="array">List (comma separated)</option>
                    <option value="int">Number</option>
                    <option value="bool">Yes or No</option>
                </select>

                {/* Button to remove the custom property */}
                <FaTrash
                    style={{ cursor: 'pointer', fontSize: "2em" }}
                    onClick={() => handleRemoveAvailableCustomProperty(index)}  // Call delete function when clicked
                />

            </div>
        ));
    };

    const handleFunctionUpdateParametersAndData = (updateParams = {}, updateData = {}, action = "update", key = null, index = null) => {
        setMenuData((prevMenu) => {
            const updatedMenu = deepClone(prevMenu);

            // Update the correct function within search_suppliers_functions or ask_us_a_question_functions
            ['search_suppliers_functions', 'ask_us_a_question_functions'].forEach((funcType) => {
                if (updatedMenu.functions[funcType]) {
                    updatedMenu.functions[funcType] = updatedMenu.functions[funcType].map((func) => {
                        if (func.button_id === selectedFunction?.button_id) {
                            let updatedParameters = {
                                ...func.parameters,
                                custom_properties: {
                                    ...func.parameters.custom_properties,
                                    ...(updateParams?.custom_properties || {}) // Only merge if updateParams.custom_properties exists
                                }
                            };

                            // Directly update vertical_id if provided in updateParams
                            if (updateParams?.vertical_id !== undefined) {
                                updatedParameters.vertical_id = updateParams.vertical_id;
                            }

                            let updatedData = { ...func.data, ...updateData };

                            // Handle array updates, additions, and removals
                            if (action === "addToArray" && key) {
                                updatedParameters.custom_properties[key] = [
                                    ...(updatedParameters.custom_properties[key] || []),
                                    ""
                                ];
                            } else if (action === "removeFromArray" && key !== null && index !== null) {
                                if (Array.isArray(updatedParameters.custom_properties[key])) {
                                    updatedParameters.custom_properties[key] = updatedParameters.custom_properties[key].filter((_, i) => i !== index);
                                    if (updatedParameters.custom_properties[key].length === 0) {
                                        delete updatedParameters.custom_properties[key];
                                    }
                                }
                            } else if (action === "removeProperty" && key) {
                                delete updatedParameters.custom_properties[key];
                            }

                            return {
                                ...func,
                                parameters: updatedParameters,
                                data: updatedData,
                            };
                        }
                        return func;
                    });
                }
            });

            return updatedMenu;
        });

        // Update selectedFunction state separately
        setSelectedFunction((prevFunction) => {
            let updatedParameters = {
                ...prevFunction.parameters,
                custom_properties: {
                    ...prevFunction.parameters.custom_properties,
                    ...(updateParams?.custom_properties || {}) // Only merge if updateParams.custom_properties exists
                }
            };

            // Update vertical_id in selectedFunction parameters if provided
            if (updateParams?.vertical_id !== undefined) {
                updatedParameters.vertical_id = updateParams.vertical_id;
            }

            let updatedData = { ...prevFunction.data, ...updateData };

            if (action === "addToArray" && key) {
                updatedParameters.custom_properties[key] = [
                    ...(updatedParameters.custom_properties[key] || []),
                    ""
                ];
            } else if (action === "removeFromArray" && key !== null && index !== null) {
                if (Array.isArray(updatedParameters.custom_properties[key])) {
                    updatedParameters.custom_properties[key] = updatedParameters.custom_properties[key].filter((_, i) => i !== index);
                    if (updatedParameters.custom_properties[key].length === 0) {
                        delete updatedParameters.custom_properties[key];
                    }
                }
            } else if (action === "removeProperty" && key) {
                delete updatedParameters.custom_properties[key];
            }

            return {
                ...prevFunction,
                parameters: updatedParameters,
                data: updatedData,
            };
        });
    };

    const handleIconChange = (categoryKey, selectedIcon) => {
        setSelectedFunction((prevFunction) => {
            const updatedCategories = {
                ...prevFunction.data.categories,
                [categoryKey]: {
                    ...prevFunction.data.categories[categoryKey],
                    icon: selectedIcon,
                },
            };
            return {
                ...prevFunction,
                data: {
                    ...prevFunction.data,
                    categories: updatedCategories,
                },
            };
        });
    };

    return (
        <>
            <div className="VerticalTreeBreakdown">
                {menuData && (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid #ccc', position: 'relative', padding: '1rem' }}>
                            <h4 className='m-0'>Layers</h4>
                            <div style={{ position: 'absolute', right: '1rem', display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <FaFile
                                    onClick={selectedFunction ? undefined : handleShowModal}
                                    disabled={selectedFunction}
                                    className='fa-icon'
                                />
                                <FaCopy
                                    onClick={() => {
                                        if (selectedMenuKey && menuData.key !== selectedMenuKey) {
                                            copyMenuItem(selectedMenuKey);
                                        } else if (selectedFunction?.button_id) {
                                            copyMenuItem(selectedFunction.button_id);
                                        }
                                    }}
                                    className='fa-icon'
                                    disabled={!((selectedMenuKey && menuData.key !== selectedMenuKey) || selectedFunction?.button_id)}
                                />
                                <FaPaste
                                    onClick={() => {
                                        if (selectedMenuKey) {
                                            pasteMenuItem(selectedMenuKey);
                                        }
                                    }}
                                    className='fa-icon'
                                    disabled={!clipboard || !selectedMenuKey}
                                />
                                <FaUndo
                                    onClick={handleUndo}
                                    className='fa-icon'
                                    disabled={undoStack.length === 0}
                                />
                                <FaRedo
                                    onClick={handleRedo}
                                    className='fa-icon'
                                    disabled={redoStack.length === 0}
                                />
                                <AddMenuModal
                                    show={showModal}
                                    handleClose={handleCloseModal}
                                    handleAddMenu={handleAddMenu}
                                    parentKey={selectedMenuKey}
                                />
                            </div>
                        </div>
                        <div className='p-3'>
                            <Layers
                                item={menuData}
                                vertical={menuData}
                                level={0}
                                isRoot={true}
                                setSelectedMenu={setSelectedMenuOverride}
                                setFunction={setFunction}
                                selectedMenu={selectedMenu}
                                functionExists={functionExists}
                                setSelectedMenuKey={setSelectedMenuKey}
                                selectedMenuKey={selectedMenuKey}
                                expandedStates={expandedStates}
                                setExpandedStates={setExpandedStates}
                                deleteMenuItem={deleteMenuItem}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="RightPillar">
                {selectedMenu && !selectedMenu.is_function && (
                    <div>
                        <h4 style={{ borderBottom: "1px solid #ccc" }} className='text-center py-3'>Settings</h4>
                        <div className="d-flex">
                            <div className="p-3" style={{ width: "50%" }}>
                                <div className="mb-3">
                                    <label className="form-label"><strong>Title</strong></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedMenu.title || ''}
                                        onChange={(e) => handleInputChangeInData('title', e.target.value)}
                                    />
                                </div>
                                {selectedMenu.layout && (
                                    <div className="mb-3">
                                        <label className="form-label"><strong>Button Layout:</strong></label>
                                        <select
                                            className="form-select"
                                            value={selectedMenu.layout || 'horizontal'}
                                            onChange={(e) => handleInputChangeInData('layout', e.target.value)}
                                        >
                                            <option value="vertical">Vertical</option>
                                            <option value="horizontal">Horizontal</option>
                                        </select>
                                    </div>
                                )}
                                {selectedMenu.icon_url && (
                                    <div className="mb-3">
                                        <label className="form-label"><strong>Icon URL</strong></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={selectedMenu.icon_url || ''}
                                            onChange={(e) => handleInputChangeInData('icon_url', e.target.value)}
                                        />
                                    </div>
                                )}
                                <div className="mb-3">
                                    {/* Button Group to switch between Content and HTML Content */}
                                    <ButtonGroup>
                                        <Button
                                            className={`custom-btn ${activeContent === 'content' ? 'btn-warning' : 'custom-btn-primary'} ${activeContent === 'content' ? 'disabled-btn' : ''}`}
                                            onClick={activeContent === 'content' ? null : () => handleContentSwitch('content')}
                                        >
                                            Content
                                        </Button>
                                        <Button
                                            className={`custom-btn ${activeContent === 'html_content' ? 'btn-warning disabled-btn' : 'custom-btn-primary'}`}
                                            onClick={activeContent === 'html_content' ? null : () => handleContentSwitch('html_content')}
                                        >
                                            Detailed Content
                                        </Button>
                                    </ButtonGroup>
                                </div>

                                {/* Conditionally render Content or HTML Content input based on selected button */}
                                {activeContent === 'content' && (
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            placeholder="Text entered here will be displayed above the menu options as a subheading"
                                            className="form-control"
                                            value={selectedMenu.content || ''}
                                            onChange={(e) => handleInputChangeInData('content', e.target.value)}
                                        />
                                    </div>
                                )}
                            </div>
                            <div style={{ flex: 1, paddingLeft: '10px' }}>
                                {menuData.key === selectedMenu.key && (
                                    <div className="p-3" style={{}}>
                                        <label className="form-label"><strong>Custom Properties</strong></label>
                                        &nbsp;&nbsp;<FaPlus onClick={handleAddCustomProperty} />
                                        {renderCustomProperties()}

                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="px-3">
                            {activeContent === 'html_content' && (
                                <div className="mb-3">
                                    <Editor
                                        ref={quillRef}
                                        defaultValue={selectedMenu.html_content || ''}
                                        readOnly={false} // Optional readOnly prop, can be toggled dynamically
                                        onTextChange={(htmlContent) => handleInputChangeInData('html_content', htmlContent, true)}
                                        onSelectionChange={(range, oldRange, source) => {
                                            
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {selectedFunction && (
                    <div>
                        <h4 style={{ borderBottom: "1px solid #ccc" }} className="text-center py-3">Settings</h4>
                        <div className="p-3">
                            <div className="form-group">
                                <div className="mb-3">
                                    <label className="form-label"><strong>Title</strong></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedMenu?.title || ''}
                                        onChange={(e) => handleInputChangeInData('title', e.target.value)}
                                    />
                                </div>
                                {selectedFunction?.parameters && (
                                    <div className="form-group">
                                        <div className="mb-3">
                                            <div className="row">
                                                {/* Left Column - Vertical Setting */}
                                                <div className="col-md-6">
                                                    <div className="card h-100">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-8 mb-2">
                                                                    <h5 className="card-title"><strong>Supplier Query Parameters</strong></h5>
                                                                </div>
                                                            </div>
                                                            {/* Render current custom properties if they exist */}
                                                            {selectedFunction.parameters?.custom_properties && Object.keys(selectedFunction.parameters.custom_properties).length > 0 ? (
                                                                <div className="mb-3">
                                                                    {Object.entries(selectedFunction.parameters.custom_properties).map(([key, value]) => (
                                                                        <div key={key} className="row align-items-center mb-2">
                                                                            <div className="col-6">
                                                                                <label><strong>{key.replace(/_/g, ' ')}</strong></label>
                                                                                {Array.isArray(value) && (
                                                                                    <>
                                                                                        &nbsp;&nbsp;
                                                                                        <FaPlus
                                                                                            style={{ marginTop: '-2px', cursor: 'pointer' }}
                                                                                            className='fa-icon'
                                                                                            onClick={() => handleFunctionUpdateParametersAndData((prev) => ({
                                                                                                custom_properties: {
                                                                                                    [key]: [...(prev.custom_properties[key] || []), ""]
                                                                                                }
                                                                                            }), {}, "addToArray", key
                                                                                            )}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-5">
                                                                                {Array.isArray(value) ? (
                                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                                        {value.map((item, index) => (
                                                                                            <div key={index} className="d-flex align-items-center mb-1 me-2">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    style={{ width: 'auto' }}
                                                                                                    value={item}
                                                                                                    onChange={(e) => handleFunctionUpdateParametersAndData(
                                                                                                        { custom_properties: { [key]: value.map((val, i) => i === index ? e.target.value : val) } },
                                                                                                        {}, "update"
                                                                                                    )}
                                                                                                />
                                                                                                <FaTrash
                                                                                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                                                                                    className='fa-icon'
                                                                                                    onClick={() => {
                                                                                                        const updatedArray = value.filter((_, i) => i !== index);
                                                                                                        handleFunctionUpdateParametersAndData(
                                                                                                            { custom_properties: { [key]: updatedArray.length > 0 ? updatedArray : undefined } },
                                                                                                            {}, updatedArray.length > 0 ? "update" : "removeProperty", key
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        ))}

                                                                                    </div>
                                                                                ) : typeof value === 'boolean' ? (
                                                                                    <div className="d-flex align-items-center mb-1 me-2">
                                                                                        <select
                                                                                            className="form-select"
                                                                                            value={value ? 'yes' : 'no'}
                                                                                            onChange={(e) =>
                                                                                                handleFunctionUpdateParametersAndData(
                                                                                                    {
                                                                                                        custom_properties: { [key]: e.target.value === 'yes' }
                                                                                                    },
                                                                                                    {},
                                                                                                    "update"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <option value="yes">Yes</option>
                                                                                            <option value="no">No</option>
                                                                                        </select>
                                                                                        <FaTrash
                                                                                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                                                                                            className='fa-icon'
                                                                                            onClick={() => {
                                                                                                handleFunctionUpdateParametersAndData(
                                                                                                    {},
                                                                                                    {}, "removeProperty", key)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="d-flex align-items-center mb-1 me-2">
                                                                                        <input
                                                                                            type={typeof value === 'number' ? 'number' : 'text'}
                                                                                            className="form-control"
                                                                                            value={value}
                                                                                            onChange={(e) => handleFunctionUpdateParametersAndData(
                                                                                                {
                                                                                                    custom_properties: {
                                                                                                        [key]: typeof value === 'number' ? parseFloat(e.target.value) || 0 : e.target.value
                                                                                                    }
                                                                                                },
                                                                                                {},
                                                                                                "update"
                                                                                            )}
                                                                                        />
                                                                                        <FaTrash
                                                                                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                                                                                            className='fa-icon'
                                                                                            onClick={() => {
                                                                                                handleFunctionUpdateParametersAndData(
                                                                                                    {},
                                                                                                    {}, "removeProperty", key)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <p>No custom properties are set.</p>
                                                            )}

                                                            {/* Render available custom properties for adding if any remain to add */}
                                                            {menuData.available_custom_properties.some(
                                                                prop => !(selectedFunction.parameters?.custom_properties?.hasOwnProperty(prop.key))
                                                            ) && (
                                                                    <div className="mt-3">
                                                                        <h6>Custom Properties</h6>
                                                                        {menuData.available_custom_properties
                                                                            .filter(prop => !(selectedFunction.parameters?.custom_properties?.hasOwnProperty(prop.key)))
                                                                            .map(({ key, value }) => (
                                                                                <div key={key} className="row align-items-center mb-2">
                                                                                    <div className="col-6">
                                                                                        <label>{key.replace(/_/g, ' ')}</label>
                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <FaPlus
                                                                                            style={{ marginTop: '-5px', cursor: 'pointer' }}
                                                                                            className='fa-icon'
                                                                                            onClick={() => handleFunctionUpdateParametersAndData({
                                                                                                custom_properties: {
                                                                                                    ...(selectedFunction.parameters.custom_properties || {}),
                                                                                                    [key]: value === 'array' ? [""] : value === 'int' ? 0 : value === 'bool' ? false : '',
                                                                                                }
                                                                                            })}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Right Column - Custom Properties */}
                                                <div className="col-md-6">
                                                    <div className="card h-100">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-8 mb-2">
                                                                    <h5 className="card-title"><strong>Supplier Details</strong></h5>
                                                                </div>
                                                            </div>
                                                            {/* Render current categories if they exist */}
                                                            {selectedFunction.data?.categories && Object.keys(selectedFunction.data.categories).length > 0 ? (
                                                                <div className="mb-3">
                                                                    {Object.entries(selectedFunction.data.categories).map(([key, category]) => (
                                                                        <div key={key} className="row align-items-center mb-2">
                                                                            <div className="col-6">
                                                                                <label>
                                                                                    <strong>
                                                                                        {key.replace('address.city,address.postal_code', 'address').replace('custom_properties.', ' ').replace(/_/g, ' ')}
                                                                                    </strong>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-5">
                                                                                <Select
                                                                                    value={{
                                                                                        value: category.icon,
                                                                                        label: (
                                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                <FontAwesomeIcon icon={iconMap[category.icon]} style={{ marginRight: 8 }} />
                                                                                                {category.icon}
                                                                                            </div>
                                                                                        ),
                                                                                    }}
                                                                                    options={iconOptions}
                                                                                    onChange={(selectedOption) => handleIconChange(key, selectedOption.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <p>No custom properties are set.</p>
                                                            )}

                                                            {/* Render available custom properties for adding if any remain to add */}
                                                            {menuData.available_custom_properties.some(
                                                                prop => !(selectedFunction.data?.categories?.hasOwnProperty('custom_properties.' + prop.key))
                                                            ) && (
                                                                    <div className="mt-3">
                                                                        <h6>Custom Properties</h6>
                                                                        {menuData.available_custom_properties
                                                                            .filter(prop => !(selectedFunction.data?.categories?.hasOwnProperty('custom_properties.' + prop.key)))
                                                                            .map(({ key, value }) => (
                                                                                <div key={key} className="row align-items-center mb-2">
                                                                                    <div className="col-6">
                                                                                        <label>{key.replace(/_/g, ' ')}</label>
                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <FaPlus
                                                                                            style={{ marginTop: '-5px', cursor: 'pointer' }}
                                                                                            className='fa-icon'
                                                                                            onClick={() => handleFunctionUpdateParametersAndData({
                                                                                                custom_properties: {
                                                                                                    ...(selectedFunction.parameters.custom_properties || {}),
                                                                                                    [key]: value === 'array' ? [""] : value === 'int' ? 0 : value === 'bool' ? false : '',
                                                                                                }
                                                                                            })}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                            {!selectedFunction.data?.categories?.hasOwnProperty('address.city,address.postal_code') && (
                                                                <div className="row align-items-center mb-2">
                                                                    <div className="col-6">
                                                                        <label>Address</label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <FaPlus
                                                                            style={{ marginTop: '-5px', cursor: 'pointer' }}
                                                                            className='fa-icon'
                                                                            onClick={() => handleFunctionUpdateParametersAndData(null, {
                                                                                categories: {
                                                                                    ...(selectedFunction.data.categories || {}),
                                                                                    'address.city,address.postal_code': {
                                                                                        "icon": "faMapMarkerAlt",
                                                                                        "type": "string"
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mt-4">
                                            <div className="mb-2">
                                                <label className="form-label"><strong>Items per Page</strong></label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={selectedFunction.data?.settings.items_per_page || ''}
                                                    onChange={(e) => handleFunctionUpdateParametersAndData(null, { settings: { items_per_page: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
};

export default Verticals;
