import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

const TopBanner = ({ title, instance, account, environment, setEnvironment }) => {
    const name = account?.name || "User";
    const handleLogout = () => {
        instance.logoutRedirect().catch(error => console.error("Logout error:", error));
    };

    const handleEnvironmentChange = (env) => {
        setEnvironment(env);
    };

    return (
        <div className="TopBanner text-white text-center p-3 d-flex justify-content-between align-items-center">
            <h1>{title}</h1>
            <div className="d-flex align-items-center">
                {/* Button Group for Environment Selection */}
                <h2>Environment:</h2>&nbsp;&nbsp;
                <ButtonGroup className="me-3">
                    <Button 
                        variant={environment === "Staging" ? "warning" : "outline-warning"}
                        active={environment === "Staging"}
                        onClick={() => handleEnvironmentChange("Staging")}
                    >
                        Staging
                    </Button>
                    <Button 
                        variant={environment === "Production" ? "danger" : "outline-danger"}
                        active={environment === "Production"}
                        onClick={() => handleEnvironmentChange("Production")}
                    >
                        Production
                    </Button>
                </ButtonGroup>
                
                {/* Dropdown for User Menu */}
                <DropdownButton
                    id="dropdown-basic-button"
                    title={name}
                    variant="warning"
                    align="end"
                >
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    );
};

export default TopBanner;
