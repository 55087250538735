import React from 'react';
import { Modal, Button, Card, Form, Row, Col, Spinner } from 'react-bootstrap';
import { FaPlus, FaTrash } from 'react-icons/fa';
import SupplierLogoUploader from './SupplierLogoUploader';

const EditSupplierModal = ({ supplier, setSupplier, availableCustomProperties, show, handleClose, handleSave, uploadFile, loading }) => {
    if (!supplier) return null;

    const handleSaveData = () => {
        handleSave(supplier); // Pass the updated supplier object to the save function
    };

    const handleInputChange = (field, value) => {
        setSupplier((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleUploadedLogoSave = (url) => {
        setSupplier((prev) => {
            const updatedSupplier = {
                ...prev,
                logo_url: url, // Correctly update logo_url without using dynamic keys
            };

            // Call handleSave with the updated supplier
            handleSave(updatedSupplier, false);
            return updatedSupplier;
        });
    };

    const handleAddressChange = (field, value) => {
        setSupplier((prev) => ({
            ...prev,
            address: {
                ...prev.address,
                [field]: value,
            }
        }));
    };

    const handleStreetAddressChange = (field, value) => {
        setSupplier((prev) => ({
            ...prev,
            address: {
                ...prev.address,
                street_address: {
                    ...prev.address.street_address,
                    [field]: value,
                }
            }
        }));
    };

    const handleAddNewItem = (key) => {
        setSupplier((prev) => ({
            ...prev,
            custom_properties: {
                ...prev.custom_properties,
                [key]: [...(prev.custom_properties[key] || []), '']
            }
        }));
    };

    const handleRemoveItem = (key, index) => {
        setSupplier((prev) => ({
            ...prev,
            custom_properties: {
                ...prev.custom_properties,
                [key]: prev.custom_properties[key].filter((_, i) => i !== index)
            }
        }));
    };

    const handleCustomPropertyChange = (key, index, value) => {
        setSupplier((prev) => ({
            ...prev,
            custom_properties: {
                ...prev.custom_properties,
                [key]: prev.custom_properties[key].map((item, i) => (i === index ? value : item))
            }
        }));
    };

    const renderCustomProperty = (key, type, value) => {
        if (type === 'array') {
            const arrayValue = Array.isArray(value) ? value : [];
            return (
                <Form.Group className="mb-3" controlId={`custom_${key}`} key={key}>
                    <div className="d-flex justify-content-between align-items-center">
                        <Form.Label>{key.replace(/_/g, ' ')}</Form.Label>
                        <FaPlus className="p-0 fa-icon" onClick={() => handleAddNewItem(key)} />
                    </div>
                    {arrayValue.map((item, index) => (
                        <div key={`${key}-${index}`} className="d-flex align-items-center mb-2">
                            <Form.Control
                                type="text"
                                value={item}
                                placeholder={`Enter ${key} item`}
                                onChange={(e) => handleCustomPropertyChange(key, index, e.target.value)}
                            />
                            <FaTrash className="p-0 ms-2 fa-icon" onClick={() => handleRemoveItem(key, index)} />
                        </div>
                    ))}
                </Form.Group>
            );
        } else if (type === 'string') {
            return (
                <Form.Group className="mb-3" controlId={`custom_${key}`} key={key}>
                    <Form.Label>{key.replace(/_/g, ' ')}</Form.Label>
                    <Form.Control
                        type="text"
                        value={value || ''}
                        placeholder={`Enter ${key}`}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                    />
                </Form.Group>
            );
        } else if (type === 'int') {
            return (
                <Form.Group className="mb-3" controlId={`custom_${key}`} key={key}>
                    <Form.Label>{key.replace(/_/g, ' ')}</Form.Label>
                    <Form.Control
                        type="number"
                        value={value || 0}
                        placeholder={`Enter ${key}`}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                    />
                </Form.Group>
            );
        }
        return null;
    };

    const properties = Array.isArray(availableCustomProperties)
        ? availableCustomProperties
        : availableCustomProperties[supplier.vertical_id] || [];

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Supplier</Modal.Title>
            </Modal.Header>
            <Modal.Body className='edit-supplier-modal-body'>
                <Form>
                    <Card className="mb-3">
                        <Card.Header>Basic Information</Card.Header>
                        <Card.Body>
                            <div className="d-flex">
                                {/* Left Side: Form Fields */}
                                <div className="flex-grow-1">
                                    <Form.Group className="mb-3" controlId="supplier_name">
                                        <Form.Label>Supplier Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={supplier.name || ''}
                                            onChange={(e) => handleInputChange("name", e.target.value)}
                                            placeholder="Enter supplier name"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="supplier_phone">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            value={supplier.phone_number || ''}
                                            onChange={(e) => handleInputChange("phone_number", e.target.value)}
                                            placeholder="Enter phone number"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="supplier_website">
                                        <Form.Label>Website URL</Form.Label>
                                        <Form.Control
                                            type="url"
                                            value={supplier.website_url || ''}
                                            onChange={(e) => handleInputChange("website_url", e.target.value)}
                                            placeholder="Enter website URL"
                                        />
                                    </Form.Group>
                                </div>

                                {/* Right Side: Logo Preview */}
                                <div className="ms-3">
                                    {supplier.logo_url && (
                                        <div
                                            style={{
                                                width: "250px",
                                                height: "250px",
                                                border: "1px solid #ddd",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "5px",
                                                overflow: "hidden",
                                                backgroundColor: "#f8f9fa",
                                            }}
                                        >
                                            <img
                                                src={supplier.logo_url}
                                                alt="Supplier Logo"
                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <SupplierLogoUploader
                                supplier={supplier}
                                handleInputChange={handleInputChange}
                                uploadFile={uploadFile}
                                handleUploadedLogoSave={handleUploadedLogoSave}
                            />
                        </Card.Body>
                    </Card>
                    <Card className="mb-3">
                        <Card.Header>Address</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="supplier_street_address">
                                        <Form.Label>Street Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={supplier.address?.street_address?.formatted_address_line || ''}
                                            onChange={(e) => handleStreetAddressChange("formatted_address_line", e.target.value)}
                                            placeholder="Enter street address"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="supplier_city">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={supplier.address?.city || ''}
                                            onChange={(e) => handleAddressChange("city", e.target.value)}
                                            placeholder="Enter city"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="supplier_country">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={supplier.address?.country_fullname || ''}
                                            onChange={(e) => handleAddressChange("country_fullname", e.target.value)}
                                            placeholder="Enter country"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="supplier_postal_code">
                                        <Form.Label>Postal Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={supplier.address?.postal_code || ''}
                                            onChange={(e) => handleAddressChange("postal_code", e.target.value)}
                                            placeholder="Enter postal code"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className="mb-3">
                        <Card.Header>Custom Properties</Card.Header>
                        <Card.Body>
                            {properties.map((property) => {
                                const value = supplier.custom_properties[property.key] || [];
                                return renderCustomProperty(property.key, property.value, value);
                            })}
                        </Card.Body>
                    </Card>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger'>Delete</Button>
                <Button variant="warning" onClick={handleSaveData} disabled={loading}>
                    {loading ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        'Save Changes'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditSupplierModal;
